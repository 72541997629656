import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Meta from "../components/Meta";
import en from '../contents/metas/en';
import tr from '../contents/metas/tr';
import { set_error_page } from '../redux/actions/appActions'
import { useDispatch } from 'react-redux';

export default function Custom404() {

    const router = useRouter();
    let t = router.locale === 'tr' ? tr : en;
    const dispatch = useDispatch();

    useEffect(() => {
        if (window !== null) {
            localStorage.setItem("errorPage", true);
        }
        dispatch(set_error_page(true))
    }, [dispatch])



    return (
        <>
            <Meta
                title={t.errorPage.title}
                keywords={t.errorPage.keywords}
                description={t.errorPage.description} />
            <div className="container">
                <div className="row" style={{ minHeight: "60vh", justifyContent: "center", alignItems: "center" }}>
                    <div className="col-12" style={{ alignSelf: "center" }}>
                        <h1 className="text-center text-8"> {t.errorPage.definition}</h1>
                    </div>
                </div>
            </div>
        </>)


}



